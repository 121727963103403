// import React from "react"
// import { graphql } from "gatsby"
// import Img from "gatsby-image"

// export default props => {
//   return (
//     <div>
//       <h1>Hello gatsby-image</h1>
//       <Img fluid={props.data.black.childImageSharp.fluid} />
//     </div>
//   )
// }

// export const query = graphql`
//   query {
//     black: file(relativePath: { eq: "black.png" }){
//       childImageSharp {
//         fluid(maxWidth: 1000) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `


import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import Button from "../components/Button";
import SEO from "../components/seo"

const NotFoundNotice = styled.div`
    margin: 160px auto;
    max-width: 960px;

`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <NotFoundNotice>
      <h2>Under construction</h2>
      <h3>You just hit a route that doesn&#39;t exist... the sadness. 🤷🏻‍</h3>
      <Button 
        link="/"
        text="← Work"
      />
    </NotFoundNotice>
  </Layout>
)

export default NotFoundPage
